<template>
  <div class="c-button">
    <VPopover v-if="tooltip" trigger="hover">
      <GenericButton :disabled="disabled" @click="onClick">
        <img v-if="imgSrc" :src="imgSrc" :title="iconTitle" />
        <span v-if="text">{{ text }}</span>
      </GenericButton>
      <template v-if="tooltip" slot="popover">
        <main>{{ tooltip }}</main>
      </template>
    </VPopover>
    <GenericButton v-else :disabled="disabled" @click="onClick">
      <img v-if="imgSrc" :src="imgSrc" :title="iconTitle" />
      <span v-if="text">{{ text }}</span>
    </GenericButton>
  </div>
</template>

<script>
import { VPopover } from 'v-tooltip'
import GenericButton from '@/components/shared/GenericButton.vue'

export default {
  components: {
    VPopover,
    GenericButton,
  },

  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    iconTitle: {
      type: String,
    },
    text: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.c-button {
  & .button {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
</style>
