<template>
  <button type="button" class="button" :disabled="disabled" @click="onClick">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>
