<i18n>
  {
    "de": {
      "exportCsvButton": "Daten (CSV)",
      "exportPngButton": "Bild (PNG)"
    }
  }
</i18n>

<template>
  <div class="c-exportable-bar-chart">
    <BarChart class="chart-container" :chart-data="chartData" :options="options" @onChartMounted="onChartMounted" />
    <div
      ref="exportWrapper"
      class="export-wrapper"
      :style="{ width: `${imageSize.width}px`, height: `${imageSize.height}px` }"
    >
      <canvas ref="exportCanvas" />
    </div>
    <div class="download-buttons">
      <Button :img-src="'/icons/download.svg'" :text="$t('exportCsvButton')" @click="exportAsCsv" />
      <Button :img-src="'/icons/download.svg'" :text="$t('exportPngButton')" @click="exportAsPng" />
    </div>
  </div>
</template>

<script>
import exportService from '@/services/export-service.js'

import BarChart from '@/components/shared/charts/BarChart.vue'
import Button from '@/components/shared/Button.vue'

export default {
  components: {
    BarChart,
    Button,
  },

  props: {
    chartData: {
      type: Object,
    },
    options: {
      type: Object,
    },
    imageSize: {
      type: Object,
      default: () => ({
        width: 720,
        height: 360,
      }),
    },
  },

  data() {
    return {
      chartInstance: null,
    }
  },

  methods: {
    exportAsCsv() {
      let data = exportService.chartToCsv(this.chartData, this.options)
      exportService.saveCsv(data.join('\n'), 'export.csv')
    },

    exportAsPng() {
      // Render the export wrapper
      this.$refs.exportWrapper.style.display = 'block'

      const { chart } = exportService.chartToExportCanvas(
        this.chartInstance.getCurrentChart(),
        this.$refs.exportCanvas,
        this.chartData,
        this.options,
        this.imageSize
      )

      if (chart) {
        exportService.savePng(chart, 'chart.png')
        // Hide the export wrapper after exporting
        this.$refs.exportWrapper.style.display = 'none'
        // Remove export chart instance
        chart.destroy()
      }
    },

    onChartMounted(chartRef) {
      this.chartInstance = chartRef
    },
  },
}
</script>

<style lang="scss" scoped>
.c-exportable-bar-chart {
  position: relative;

  & .download-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    height: auto;
    padding-top: 10px;
  }

  & .chart-container {
    position: relative;
  }

  & .export-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    visibility: hidden;
  }
}
</style>
